<script>
import { PhxTabPanel, PhxTabs, PhxIcon } from '@phx-styleguides/als';

export default {
  components: {
    PhxTabs,
    PhxTabPanel,
    PhxIcon,
  },

  props: {
    regions: {
      type: Object,
      required: true,
    },

    currentRegion: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentTab: '',
    };
  },

  created() {
    this.currentTab = `results-${this.currentRegion}`;
  },

  methods: {
    changeTab(id) {
      this.currentTab = id;
    },

    getTabClasses(tabId) {
      return [
        'country-dispatcher-tabs__countries',
        {
          'country-dispatcher-tabs__countries--with-columns': Object.keys(this.regions[tabId].items).length > 8,
        },
      ];
    },

    getTabItemClasses(isWorldwide) {
      return [
        'country-dispatcher-tabs__country',
        {
          'country-dispatcher-tabs__country--no-shopping': isWorldwide,
        },
      ];
    },
  },
};
</script>

<template>
  <phx-tabs
    v-cloak
    id="country-dispatcher-tabs"
    class="country-dispatcher-tabs mt-lg"
    :value="currentTab"
    orientation="vertical"
    tab-buttons-icon="chevron-right"
    data-cy="country-dispatcher-panel"
    @change="changeTab"
  >
    <phx-tab-panel v-for="(region, name) in regions" :id="`results-${name}`" :key="name" :label="name">
      <div v-if="region.alert" role="alert" class="alert country-dispatcher-tabs__alert alert--secondary">
        <div class="alert__icon">
          <phx-icon icon="info" />
        </div>
        <div v-html="region.alert"></div>
      </div>

      <ul :class="getTabClasses(name)">
        <li v-for="(countries, key) in region.items" :key="key" :class="getTabItemClasses(region.isWorldwide)">
          <template v-if="!region.isWorldwide">
            <phx-icon v-if="countries[0].icon" icon="cart" />

            <template v-for="(localisation, k) in countries" :key="k">
              <a
                :href="localisation.refererUrl"
                class="country-dispatcher-tabs__localization text-link"
                :data-cy="`country-dispatcher-localisation-${localisation.label}`"
              >
                <span>{{ localisation.label }}</span>
                <span class="country-dispatcher-tabs__language-code">({{ localisation.code.toUpperCase() }})</span>
              </a>
              <template v-if="k !== countries.length - 1"> / </template>
            </template>
          </template>

          <a v-else :href="countries.refererUrl" class="country-dispatcher-tabs__localization text-link">
            <span>{{ countries.label }}</span>
          </a>
        </li>
      </ul>
    </phx-tab-panel>
  </phx-tabs>
</template>
